import React, { useCallback, useEffect, useState } from 'react'
import { logout, useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { useIdleTimer } from 'react-idle-timer'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormGroup, TextField } from '@mui/material'
import { API } from '../../api/api'
import { showDialogMessage } from '../../state/messageActions'
import localStore from '../../state/LocalStore'

const strings = {
    button: {
        login: 'Zaloguj',
    },
    label: {
        password: 'Hasło',
    },
    placeholder: {
        password: 'Podaj hasło',
    },
    message: {
        info: 'Ze względu na brak aktywności aplikacja została zablokowana. Potwierdź swoją tożsamość podając hasło używane do zalogowania się.',
        error: 'Weryfikacja zakończyła się błędem. Zaloguj się ponownie.',
    },
}

const timeout = parseInt(process.env.REACT_APP_TIMEOUT as string)

const Idle: React.FunctionComponent = () => {
    const { appDispatch, sessionDispatch } = useStateDispatchContext()
    const { sessionState } = useStateContext()
    const [locked, setLocked] = useState(false)

    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState(false)

    const onIdle = (): void => {
        setPassword('')
        setLocked(true)
        localStore.lock()
    }

    const onActive = (): void => {
        setLocked(false)
    }

    const onMessage = (data: any): void => {
        if (data === 'init' && locked) {
            setLocked(false)
            appDispatch(
                showDialogMessage(strings.message.error, () => {
                    logout(sessionDispatch)
                })
            )
        }
    }

    const { reset, pause, resume, activate, message } = useIdleTimer({
        onIdle,
        onActive,
        onMessage,
        timeout,
        throttle: 500,
        stopOnIdle: true,
        crossTab: true,
        syncTimers: 200,
    })

    useEffect(() => {
        message('init', false)
    }, [])

    useEffect(() => {
        if (sessionState.auth !== null) {
            resume()
        } else {
            reset()
            pause()

            setLocked(false)
        }
    }, [sessionState.auth])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassword(event.target.value)
    }, [])

    async function onDialogButtonClick(): Promise<void> {
        if (password.length === 0) {
            return
        }

        setLoading(true)

        try {
            await API.account.verify(password)
            localStore.unlock()
            activate()
        } catch (error) {
            setLocked(false)
            appDispatch(
                showDialogMessage(strings.message.error, () => {
                    logout(sessionDispatch)
                })
            )
        } finally {
            setLoading(false)
        }
    }

    function handleLoginClick(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault()

        if (loading) {
            return
        }

        onDialogButtonClick()
    }

    return (
        <Dialog
            open={locked}
            disableEscapeKeyDown={true}
        >
            <DialogContent>
                <DialogContentText>{strings.message.info}</DialogContentText>

                <form onSubmit={handleLoginClick}>
                    <FormGroup>
                        <TextField
                            id="password"
                            name="password"
                            value={password}
                            multiline={false}
                            fullWidth={true}
                            label={strings.label.password}
                            margin="normal"
                            placeholder={strings.placeholder.password}
                            onChange={onTextFieldChange}
                            inputProps={{ maxLength: 64 }}
                            autoComplete="off"
                            required={true}
                            type="password"
                            variant="standard"
                        />
                    </FormGroup>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onDialogButtonClick}
                    disabled={loading}
                >
                    {strings.button.login}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(Idle)
