import { Button, FormGroup, Grid, IconButton, Snackbar, styled, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import Validator from 'validator'
import { nameofFactory } from '../../utils/nameofFactory'

const strings = {
    button: {
        login: 'Zaloguj',
    },
    error: {
        loginFailed: 'Logowanie zakończone niepowodzeniem',
        passwordIsRequired: 'Hasło jest wymagane',
        usernameIsRequired: 'Login jest wymagany',
        codeIsRequired: 'Kod jest wymagany',
    },
    label: {
        password: 'Hasło',
        username: 'Login',
        code: 'Kod',
    },
    placeholder: {
        password: 'Podaj hasło',
        username: 'Podaj login',
        code: 'Podaj otrzymany kod',
    },
}

const StyledContainer = styled(Grid)({
    width: 310,
})

const StyledLoginButtonContainer = styled(Grid)({
    marginTop: 16,
})

enum FormFieldNames {
    UserName = 'username',
    Password = 'password',
    Code = 'code',
}

interface FormErrors {
    username?: string
    password?: string
    code?: string
    form?: string
}

interface OwnProps {
    readonly twofa: (username: string, password: string) => Promise<void>
    readonly authorize: (username: string, password: string, code: string) => Promise<void>
}

const LoginForm: React.FunctionComponent<OwnProps> = ({ twofa, authorize }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [authorized, setAuthorized] = useState<boolean>(false)
    const [code, setCode] = useState<string>('')

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.UserName: {
                setUsername(event.target.value)
                break
            }
            case FormFieldNames.Password: {
                setPassword(event.target.value)
                break
            }
            case FormFieldNames.Code: {
                setCode(event.target.value)
                break
            }
        }
    }, [])

    const onFormErrorClose = useCallback(() => {
        const nameof = nameofFactory<FormErrors>()
        setErrors((errors) => _.omit(errors, nameof('form')))
    }, [])

    function handleLoginClick(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault()

        const errors: FormErrors = {}

        if (Validator.isEmpty(username)) {
            errors.username = strings.error.usernameIsRequired
        }

        if (Validator.isEmpty(password)) {
            errors.password = strings.error.passwordIsRequired
        }

        if (authorized && Validator.isEmpty(code)) {
            errors.code = strings.error.codeIsRequired
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        setLoading(true)

        if (authorized) {
            authorize(username, password, code).catch((error) => {
                if (error === null) {
                    setLoading(false)
                } else {
                    setErrors({ form: strings.error.loginFailed })
                    setLoading(false)
                }
            })
        } else {
            twofa(username, password)
                .then(() => {
                    setAuthorized(true)
                    setLoading(false)
                })
                .catch((error) => {
                    if (error === null) {
                        setLoading(false)
                    } else {
                        setErrors({ form: strings.error.loginFailed })
                        setLoading(false)
                    }
                })
        }
    }

    return (
        <Grid
            container={true}
            justifyContent="center"
            spacing={0}
        >
            <StyledContainer item={true}>
                <Snackbar
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                    }}
                    open={!!errors.form}
                    autoHideDuration={6000}
                    onClose={onFormErrorClose}
                    message={<span>{errors.form}</span>}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            onClick={onFormErrorClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
                <form onSubmit={handleLoginClick}>
                    <FormGroup>
                        <TextField
                            id={FormFieldNames.UserName}
                            name={FormFieldNames.UserName}
                            value={username}
                            multiline={false}
                            fullWidth={true}
                            label={strings.label.username}
                            margin="none"
                            placeholder={strings.placeholder.username}
                            onChange={onTextFieldChange}
                            helperText={errors.username}
                            error={errors.username !== undefined}
                            inputProps={{ maxLength: 64 }}
                            autoComplete="username"
                            type="text"
                            variant="standard"
                            disabled={authorized}
                        />

                        <TextField
                            id={FormFieldNames.Password}
                            name={FormFieldNames.Password}
                            value={password}
                            multiline={false}
                            fullWidth={true}
                            label={strings.label.password}
                            margin="normal"
                            placeholder={strings.placeholder.password}
                            onChange={onTextFieldChange}
                            helperText={errors.password}
                            error={errors.password !== undefined}
                            inputProps={{ maxLength: 64 }}
                            autoComplete="current-password"
                            type="password"
                            variant="standard"
                            disabled={authorized}
                        />

                        {authorized && (
                            <TextField
                                id={FormFieldNames.Code}
                                name={FormFieldNames.Code}
                                value={code}
                                multiline={false}
                                fullWidth={true}
                                label={strings.label.code}
                                focused={true}
                                margin="normal"
                                placeholder={strings.placeholder.code}
                                onChange={onTextFieldChange}
                                helperText={errors.code}
                                error={errors.code !== undefined}
                                inputProps={{ maxLength: 64 }}
                                type="password"
                                variant="standard"
                            />
                        )}
                    </FormGroup>

                    <StyledLoginButtonContainer
                        container={true}
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item={true}>
                            <Button
                                color="primary"
                                disabled={loading}
                                type="submit"
                                variant="contained"
                            >
                                {strings.button.login}
                            </Button>
                        </Grid>
                    </StyledLoginButtonContainer>
                </form>
            </StyledContainer>
        </Grid>
    )
}

export default React.memo(LoginForm)
