import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { MenuItemType } from '../../model/MenuItemType'
import { Box, Button, CircularProgress, Paper, Stack, styled } from '@mui/material'
import { Schedule, ScheduleStatus, UserBranchAccess, UserRole, hasRole, hasUserBranchAccess } from '../../api/response'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { drawerWidth } from '../../components/AppDrawer'
import { mainPadding } from '../../components/AppCanvas'
import ScheduleTableHeader from './ScheduleTableHeader'
import ScheduleHeader from './ScheduleHeader'
import { ScheduleRequest } from '../../api/request'
import ScheduleTableBody from './ScheduleTableBody'
import ScheduleShiftDialog, { ScheduleShiftDialogData } from './ScheduleShiftDialog'
import ScheduleDayDialog, { ScheduleDayDialogData } from './ScheduleDayDialog'
import UserShiftDialog, { UserShiftDialogData } from './UserShiftDialog'
import SplitUserShiftDialog, { SplitUserShiftDialogData } from './SplitUserShiftDialog'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import FileSaver from 'file-saver'
import ScheduleShiftHistoryDialog, { ScheduleShiftHistoryDialogData } from './ScheduleShiftHistoryDialog'
import UserShiftHistoryDialog, { UserShiftHistoryDialogData } from './UserShiftHistoryDialog'
import UserShiftEndExplanationDialog, { UserShiftEndExplanationDialogData } from './UserShiftEndExplanationDialog'

const strings = {
    button: {
        refresh: 'Odśwież',
    },
}

enum QueryParam {
    BranchId = 'branchId',
    Year = 'year',
    Month = 'month',
}

const getParamNumberValue = (searchParams: URLSearchParams, queryParam: QueryParam, defaultValue: number): number => {
    let param = parseInt(searchParams.get(queryParam) ?? '')
    if (isNaN(param)) {
        param = defaultValue
    }

    return param
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

const StyledTableContainer = styled(Box)(({ theme }) => ({
    maxHeight: 'calc(100vh - 256px)',
    width: `calc(100vw - ${drawerWidth}px - ${theme.spacing(4 * mainPadding)})`,
    margin: theme.spacing(mainPadding),
    overflow: 'auto',
}))

const StyledTable = styled('table')({
    whiteSpace: 'nowrap',
    margin: 0,
    borderCollapse: 'separate',
    borderSpacing: 0,
    tableLayout: 'fixed',
    border: '1px solid black',
    '& td': {
        border: '1px solid black',
    },
    '& th': {
        border: '1px solid black',
        position: 'sticky',
    },
})

const SchedulePage: React.FunctionComponent = () => {
    const today = useMemo(() => dayjs().startOf('day'), [])

    const { sessionState } = useStateContext()
    const { appDispatch } = useStateDispatchContext()
    const [searchParams, setSearchParams] = useSearchParams()

    const [year, setYear] = useState<number>(() => getParamNumberValue(searchParams, QueryParam.Year, today.year()))
    const [month, setMonth] = useState<number>(() => getParamNumberValue(searchParams, QueryParam.Month, today.month() + 1))
    const [branchId, setBranchId] = useState<number>(() => getParamNumberValue(searchParams, QueryParam.BranchId, sessionState.account!.branch.id))
    const [showAll, setShowAll] = useState<boolean>(false)
    const [schedule, setSchedule] = useState<Schedule | null>(null)
    const [scheduleStatus, setScheduleStatus] = useState<ScheduleStatus | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [scheduleShiftHistoryDialogOpen, setScheduleShiftHistoryDialogOpen] = useState<boolean>(false)
    const [scheduleShiftHistoryDialogData, setScheduleShiftHistoryDialogData] = useState<ScheduleShiftHistoryDialogData | null>(null)
    const [userShiftHistoryDialogOpen, setUserShiftHistoryDialogOpen] = useState<boolean>(false)
    const [userShiftHistoryDialogData, setUserShiftHistoryDialogData] = useState<UserShiftHistoryDialogData | null>(null)
    const [dayDialogOpen, setDayDialogOpen] = useState<boolean>(false)
    const [dayDialogData, setDayDialogData] = useState<ScheduleDayDialogData | null>(null)
    const [scheduleShiftDialogOpen, setScheduleShiftDialogOpen] = useState<boolean>(false)
    const [scheduleShiftDialogData, setScheduleShiftDialogData] = useState<ScheduleShiftDialogData | null>(null)
    const [userShiftDialogOpen, setUserShiftDialogOpen] = useState<boolean>(false)
    const [userShiftDialogData, setUserShiftDialogData] = useState<UserShiftDialogData | null>(null)
    const [userShiftEndExplanationDialogOpen, setUserShiftEndExplanationDialogOpen] = useState<boolean>(false)
    const [userShiftEndExplanationDialogData, setUserShiftEndExplanationDialogData] = useState<UserShiftEndExplanationDialogData | null>(null)
    const [splitUserShiftDialogOpen, setSplitUserShiftDialogOpen] = useState<boolean>(false)
    const [splitUserShiftDialogData, setSplitUserShiftDialogData] = useState<SplitUserShiftDialogData | null>(null)

    const date = useMemo(
        () =>
            dayjs()
                .year(year)
                .month(month - 1)
                .date(1),
        [year, month]
    )

    const canManageSchedule = useMemo(() => {
        if (schedule === null) {
            return false
        }

        const account = sessionState.account!
        const scheduleBranchId = schedule.branch.id
        const userBranchId = account.branch.id

        if (scheduleBranchId === userBranchId) {
            return hasRole(account, UserRole.ManageBranchSchedules)
        }

        if (hasRole(account, UserRole.ManageUserBranchesSchedules)) {
            const additionalBranches = account.branches.filter(
                (item) => item.branch.id === scheduleBranchId && hasUserBranchAccess(item, UserBranchAccess.ManageBranchSchedules)
            )

            if (additionalBranches.length > 0) {
                return true
            }
        }

        return hasRole(account, UserRole.ManageAllSchedules)
    }, [schedule])

    const canManageUserShifts = useMemo(() => {
        if (schedule === null) {
            return false
        }

        const account = sessionState.account!
        const scheduleBranchId = schedule.branch.id
        const userBranchId = account.branch.id

        if (scheduleBranchId === userBranchId) {
            return hasRole(account, UserRole.ManageBranchUserShifts)
        }

        if (hasRole(account, UserRole.ManageUserBranchesUserShifts)) {
            const additionalBranches = account.branches.filter(
                (item) => item.branch.id === scheduleBranchId && hasUserBranchAccess(item, UserBranchAccess.ManageBranchUserShifts)
            )

            if (additionalBranches.length > 0) {
                return true
            }
        }

        return hasRole(account, UserRole.ManageAllUserShifts)
    }, [schedule])

    const canShowAll = useMemo(() => {
        return canManageSchedule && hasRole(sessionState.account!, UserRole.ShowScheduleHistory)
    }, [canManageSchedule])

    const canExportSchedule = useMemo(() => {
        return canManageSchedule && hasRole(sessionState.account!, UserRole.ExportUserSchedule)
    }, [canManageSchedule])

    const loadSchedule = useCallback(() => {
        setLoading(true)
        API.schedule
            .details(new ScheduleRequest(year, month, branchId, showAll))
            .then((schedule) => {
                setSchedule(schedule)
                setScheduleStatus(schedule.status)
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [year, month, branchId, showAll])

    const closeSchedule = useCallback((): Promise<void> => {
        if (schedule === null || schedule.status === ScheduleStatus.Closed) {
            return Promise.reject()
        }

        return API.schedule
            .close(schedule.id)
            .then(() => {
                setScheduleStatus(ScheduleStatus.Closed)
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
            })
    }, [schedule])

    const updateYear = useCallback((newValue: typeof year) => {
        setYear(newValue)
        setSchedule(null)
        setScheduleStatus(null)
        setLoading(true)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.Year, newValue.toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateMonth = useCallback((newValue: typeof year) => {
        setMonth(newValue)
        setSchedule(null)
        setScheduleStatus(null)
        setLoading(true)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.Month, newValue.toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateBranchId = useCallback((newValue: typeof branchId) => {
        setBranchId(newValue)
        setSchedule(null)
        setScheduleStatus(null)
        setLoading(true)

        setSearchParams(
            (prev) => {
                prev.set(QueryParam.BranchId, newValue.toString())

                return prev
            },
            { replace: true }
        )
    }, [])

    const updateShowAll = useCallback((newValue: typeof showAll) => {
        setShowAll(newValue)
        setSchedule(null)
        setScheduleStatus(null)
        setLoading(true)
    }, [])

    const openScheduleShiftHistoryDialog = useCallback((data: ScheduleShiftHistoryDialogData) => {
        setScheduleShiftHistoryDialogOpen(true)
        setScheduleShiftHistoryDialogData(data)
    }, [])

    const closeScheduleShiftHistoryDialog = useCallback(() => {
        setScheduleShiftHistoryDialogOpen(false)
    }, [])

    const openUserShiftHistoryDialog = useCallback((data: UserShiftHistoryDialogData) => {
        setUserShiftHistoryDialogOpen(true)
        setUserShiftHistoryDialogData(data)
    }, [])

    const closeUserShiftHistoryDialog = useCallback(() => {
        setUserShiftHistoryDialogOpen(false)
    }, [])

    const openDayDialog = useCallback((data: ScheduleDayDialogData) => {
        setDayDialogOpen(true)
        setDayDialogData(data)
    }, [])

    const closeDayDialog = useCallback(() => {
        setDayDialogOpen(false)
    }, [])

    const openScheduleShiftDialog = useCallback((data: ScheduleShiftDialogData) => {
        setScheduleShiftDialogOpen(true)
        setScheduleShiftDialogData(data)
    }, [])

    const closeScheduleShiftDialog = useCallback(() => {
        setScheduleShiftDialogOpen(false)
    }, [])

    const openUserShiftDialog = useCallback((data: UserShiftDialogData) => {
        setUserShiftDialogOpen(true)
        setUserShiftDialogData(data)
    }, [])

    const closeUserShiftDialog = useCallback(() => {
        setUserShiftDialogOpen(false)
    }, [])

    const openUserShiftEndExplanationDialog = useCallback((data: UserShiftEndExplanationDialogData) => {
        setUserShiftEndExplanationDialogOpen(true)
        setUserShiftEndExplanationDialogData(data)
    }, [])

    const closeUserShiftEndExplanationDialog = useCallback(() => {
        setUserShiftEndExplanationDialogOpen(false)
    }, [])

    const openSplitUserShiftDialog = useCallback((data: SplitUserShiftDialogData) => {
        setSplitUserShiftDialogOpen(true)
        setSplitUserShiftDialogData(data)
    }, [])

    const closeSplitUserShiftDialog = useCallback(() => {
        setSplitUserShiftDialogOpen(false)
    }, [])

    const exportSchedule = useCallback((): void => {
        if (schedule === null) {
            return
        }

        const branchName = schedule.branch.name.toLowerCase().replaceAll(' ', '-')
        const month = schedule.month.toString().padStart(2, '0')

        appDispatch(setInProgress())

        API.schedule
            .export(schedule.id)
            .then((blob: Blob) => {
                FileSaver.saveAs(blob, `raport-godzin-${branchName}-${month}-${schedule.year}.xlsx`)

                appDispatch(setSuccess())
            })
            .catch((error) => {
                appDispatch(handleApiError(error))
                appDispatch(setFailure())
            })
    }, [schedule])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Schedule))
        appDispatch(setTitle(MenuItemType.Schedule))
    }, [])

    useEffect(() => {
        loadSchedule()
    }, [year, month, branchId, showAll])

    return (
        <StyledPaper elevation={2}>
            <ScheduleHeader
                updateYear={updateYear}
                updateMonth={updateMonth}
                updateBranchId={updateBranchId}
                updateShowAll={updateShowAll}
                month={month}
                year={year}
                branchId={branchId}
                showAll={showAll}
                canShowAll={canShowAll}
                canExportSchedule={canExportSchedule}
                exportSchedule={exportSchedule}
            />

            <StyledTableContainer>
                <StyledTable>
                    <ScheduleTableHeader
                        date={date}
                        holidays={schedule?.holidays ?? []}
                        status={scheduleStatus}
                        canManageSchedule={canManageSchedule}
                        closeSchedule={closeSchedule}
                    />
                    {!!schedule && (
                        <ScheduleTableBody
                            schedule={schedule}
                            status={scheduleStatus}
                            date={date}
                            showAll={showAll}
                            canManageSchedule={canManageSchedule}
                            canManageUserShifts={canManageUserShifts}
                            canShowHistory={canShowAll}
                            canExportSchedule={canExportSchedule}
                            openScheduleShiftHistoryDialog={openScheduleShiftHistoryDialog}
                            openUserShiftHistoryDialog={openUserShiftHistoryDialog}
                            openDayDialog={openDayDialog}
                            openScheduleShiftDialog={openScheduleShiftDialog}
                            openUserShiftDialog={openUserShiftDialog}
                            openUserShiftEndExplanationDialog={openUserShiftEndExplanationDialog}
                            openSplitUserShiftDialog={openSplitUserShiftDialog}
                        />
                    )}
                </StyledTable>
            </StyledTableContainer>

            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {!loading && !schedule && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadSchedule}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {!!scheduleShiftHistoryDialogData && (
                <ScheduleShiftHistoryDialog
                    open={scheduleShiftHistoryDialogOpen}
                    data={scheduleShiftHistoryDialogData}
                    closeDialog={closeScheduleShiftHistoryDialog}
                />
            )}

            {!!userShiftHistoryDialogData && (
                <UserShiftHistoryDialog
                    open={userShiftHistoryDialogOpen}
                    data={userShiftHistoryDialogData}
                    closeDialog={closeUserShiftHistoryDialog}
                />
            )}

            {!!dayDialogData && (
                <ScheduleDayDialog
                    open={dayDialogOpen}
                    data={dayDialogData}
                    closeDialog={closeDayDialog}
                />
            )}

            {!!scheduleShiftDialogData && (
                <ScheduleShiftDialog
                    open={scheduleShiftDialogOpen}
                    data={scheduleShiftDialogData}
                    closeDialog={closeScheduleShiftDialog}
                />
            )}

            {!!userShiftDialogData && (
                <UserShiftDialog
                    open={userShiftDialogOpen}
                    data={userShiftDialogData}
                    closeDialog={closeUserShiftDialog}
                />
            )}

            {!!userShiftEndExplanationDialogData && (
                <UserShiftEndExplanationDialog
                    open={userShiftEndExplanationDialogOpen}
                    data={userShiftEndExplanationDialogData}
                    closeDialog={closeUserShiftEndExplanationDialog}
                />
            )}

            {!!splitUserShiftDialogData && (
                <SplitUserShiftDialog
                    open={splitUserShiftDialogOpen}
                    data={splitUserShiftDialogData}
                    closeDialog={closeSplitUserShiftDialog}
                />
            )}
        </StyledPaper>
    )
}

export default React.memo(SchedulePage)
