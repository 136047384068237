import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import Routes from '../../routes/Routes'
import Loading from './Loading'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StateProvider } from '../../state/stateContext'
import { CssBaseline } from '@mui/material'
import ApiErrorHandler from './ApiErrorHandler'
import MessageView from './MessageView'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { APIProvider } from '@vis.gl/react-google-maps'
import { OutputFormat, setDefaults } from 'react-geocode'
import Idle from './Idle'
import RefreshToken from './RefreshToken'

const theme = createTheme({})

setDefaults({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'pl',
    region: 'pl',
    outputFormat: OutputFormat.JSON,
})

const App: React.FunctionComponent = () => (
    <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        region="PL"
        language="pl"
    >
        <DndProvider backend={HTML5Backend}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StateProvider>
                            <CssBaseline />
                            <Idle />
                            <RefreshToken />
                            <Routes />
                            <Loading />
                            <MessageView />
                            <ApiErrorHandler />
                        </StateProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </DndProvider>
    </APIProvider>
)

export default React.memo(App)
