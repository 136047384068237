import React, { useEffect, useState } from 'react'
import { useStateContext, useStateDispatchContext } from '../../state/stateContext'
import { API } from '../../api/api'
import localStore from '../../state/LocalStore'
import { setAccessToken } from '../../state/authActions'

const RefreshToken: React.FunctionComponent = () => {
    const { sessionDispatch } = useStateDispatchContext()
    const { sessionState } = useStateContext()

    const [loading, setLoading] = useState(false)

    async function handleRefresh(refreshToken: string): Promise<void> {
        if (loading) {
            return
        }

        setLoading(true)

        try {
            const accessToken = await API.auth.refresh(refreshToken)
            localStore.storeAccessToken(accessToken)
            sessionDispatch(setAccessToken(accessToken))
        } catch (error) {
            // Ignore
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const auth = sessionState.auth
        if (auth !== null) {
            const now = Math.floor(Date.now() / 1000)
            const expiresIn = auth.expiresIn
            let delay = 30_000

            if (expiresIn < now) {
                return undefined
            } else if (expiresIn - now > 3_600) {
                delay = 3_600_000
            } else if (expiresIn - now > 1_800) {
                delay = 1_800_000
            }

            const refreshToken = auth.refreshToken

            const timeout = setTimeout(() => {
                handleRefresh(refreshToken)
            }, delay)

            return (): void => {
                clearTimeout(timeout)
            }
        }

        return undefined
    }, [sessionState.auth])

    return null
}

export default React.memo(RefreshToken)
