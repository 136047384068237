import { Branch } from './Branch'
import { UserInfo } from './UserInfo'

export class UserBranch {
    constructor(
        readonly id: number,
        readonly user: UserInfo,
        readonly branch: Branch,
        readonly start: Date,
        readonly end: Date | null,
        readonly access: UserBranchAccess[]
    ) {}
}

export enum UserBranchAccess {
    ManageBranchSchedules = 'manage_branch_schedules',
    ManageBranchUserShifts = 'manage_branch_user_shifts',
    ViewBranchSchedules = 'view_branch_schedules',
}

export const hasUserBranchAccess: (userBranch: UserBranch, access: UserBranchAccess) => boolean = (userBranch, access): boolean => {
    const hasAccess = userBranch.access.includes(access)

    if (!hasAccess && access === UserBranchAccess.ViewBranchSchedules) {
        return userBranch.access.includes(UserBranchAccess.ManageBranchSchedules)
    }

    return hasAccess
}
