import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ScheduleStatus, UserRole, hasRole } from '../../api/response'
import dayjs from 'dayjs'
import { Button, CircularProgress, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material'
import { useStateContext } from '../../state/stateContext'
import AcceptIcon from '@mui/icons-material/Check'
import DiscardIcon from '@mui/icons-material/Close'

const strings = {
    label: {
        closed: 'Zamknięty',
    },
    button: {
        close: 'Zamknij',
        cancel: 'Anuluj',
    },
}

const StyledInfoTableHeader = styled('th')(() => ({
    backgroundColor: '#000',
    color: '#fff',
}))

const StyledCloseButton = styled(Button)({
    color: '#fff',
    borderColor: '#fff',
    '&:hover': {
        borderColor: '#eee',
    },
    '&:active': {
        borderColor: '#eee',
    },
})

const StyledIconButton = styled(IconButton)({
    color: '#fff',
    borderColor: '#fff',
    '&:hover': {
        borderColor: '#eee',
    },
    '&:active': {
        borderColor: '#eee',
    },
})

const StyledCircularProgress = styled(CircularProgress)({
    color: '#fff',
})

interface OwnProps {
    readonly date: dayjs.Dayjs
    readonly status: ScheduleStatus | null
    readonly canManageSchedule: boolean
    readonly closeSchedule: () => Promise<void>
}

const ScheduleTableHeaderInfo: React.FunctionComponent<OwnProps> = ({ date, status, canManageSchedule, closeSchedule }) => {
    const [closing, setClosing] = useState<boolean>(false)
    const [confirm, setConfirm] = useState<boolean>(false)

    const { sessionState } = useStateContext()

    const isPast = useMemo((): boolean => {
        const today = dayjs().startOf('day')

        return (date.year() === today.year() && date.month() < today.month()) || date.year() < today.year()
    }, [date])

    const handleCloseSchedule = useCallback(() => {
        setClosing(true)
        closeSchedule().finally(() => {
            setClosing(false)
        })
    }, [closeSchedule])

    useEffect((): void => {
        setClosing(false)
        setConfirm(false)
    }, [status])

    if (status === ScheduleStatus.Closed) {
        return (
            <StyledInfoTableHeader>
                <Typography>{strings.label.closed}</Typography>
            </StyledInfoTableHeader>
        )
    }

    if (status === null || !(canManageSchedule && hasRole(sessionState.account!, UserRole.CloseSchedules)) || !isPast) {
        return <StyledInfoTableHeader>&nbsp;</StyledInfoTableHeader>
    }

    if (closing) {
        return (
            <StyledInfoTableHeader>
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <StyledCircularProgress size={20} />
                </Stack>
            </StyledInfoTableHeader>
        )
    }

    if (confirm) {
        return (
            <StyledInfoTableHeader>
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Tooltip title={strings.button.cancel}>
                        <StyledIconButton
                            onClick={(): void => {
                                setConfirm(false)
                            }}
                        >
                            <DiscardIcon />
                        </StyledIconButton>
                    </Tooltip>

                    <Tooltip title={strings.button.close}>
                        <StyledIconButton onClick={handleCloseSchedule}>
                            <AcceptIcon />
                        </StyledIconButton>
                    </Tooltip>
                </Stack>
            </StyledInfoTableHeader>
        )
    }

    return (
        <StyledInfoTableHeader>
            <StyledCloseButton
                variant="outlined"
                onClick={(): void => {
                    setConfirm(true)
                }}
            >
                {strings.button.close}
            </StyledCloseButton>
        </StyledInfoTableHeader>
    )
}

export default React.memo(ScheduleTableHeaderInfo)
