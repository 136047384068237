import React, { useCallback, useEffect, useState } from 'react'
import { ScheduleUser, ScheduleUserDay, ScheduleUserDayShift, UserDayShift } from '../../api/response'
import dayjs from 'dayjs'
import { Box, IconButton, Menu, MenuItem, PopoverPosition, Tooltip, Typography } from '@mui/material'
import AddScheduleShiftIcon from '@mui/icons-material/MoreTimeOutlined'
import AddUserShiftIcon from '@mui/icons-material/PersonAddOutlined'
import EditNoteIcon from '@mui/icons-material/EditNoteOutlined'
import NoteIcon from '@mui/icons-material/InfoOutlined'
import { ScheduleShiftDialogData } from './ScheduleShiftDialog'
import { styled } from '@mui/system'
import ScheduleTableBodyDayShift from './ScheduleTableBodyDayShift'
import { useStateDispatchContext } from '../../state/stateContext'
import { API } from '../../api/api'
import { handleApiError } from '../../state/apiErrorActions'
import { setInProgress } from '../../state/progressActions'
import { setSuccess } from '../../state/progressActions'
import { setFailure } from '../../state/progressActions'
import { ScheduleDayDialogData } from './ScheduleDayDialog'
import { useDrop } from 'react-dnd'
import { ScheduleShiftRequest } from '../../api/request'
import ScheduleTableBodyUserDayShift from './ScheduleTableBodyUserDayShift'
import { UserShiftDialogData } from './UserShiftDialog'
import { SplitUserShiftDialogData } from './SplitUserShiftDialog'
import { UserShiftHistoryDialogData } from './UserShiftHistoryDialog'
import { ScheduleShiftHistoryDialogData } from './ScheduleShiftHistoryDialog'
import { UserShiftEndExplanationDialogData } from './UserShiftEndExplanationDialog'

const strings = {
    button: {
        addScheduleShift: 'Dodaj zmianę',
        addUserShift: 'Dodaj czas pracy',
        addNote: 'Dodaj notatkę',
    },
}

const StyledTableCell = styled('td')<{ dayColor: string; isEmpty: boolean }>(({ dayColor, isEmpty }) => ({
    backgroundColor: dayColor,
    verticalAlign: isEmpty ? 'middle' : 'top',
}))

const StyledCenteredBox = styled(Box)({
    textAlign: 'center',
})

interface OwnProps {
    readonly scheduleId: string
    readonly user: ScheduleUser
    readonly date: dayjs.Dayjs
    readonly showAll: boolean
    readonly isEditable: boolean
    readonly canShowHistory: boolean
    readonly canAddUserShifts: boolean
    readonly canEditUserShifts: boolean
    readonly canHideUserShifts: boolean
    readonly canEndUserShifts: boolean
    readonly dayColor: string
    readonly openScheduleShiftHistoryDialog: (data: ScheduleShiftHistoryDialogData) => void
    readonly openUserShiftHistoryDialog: (data: UserShiftHistoryDialogData) => void
    readonly openDayDialog: (data: ScheduleDayDialogData) => void
    readonly openScheduleShiftDialog: (data: ScheduleShiftDialogData) => void
    readonly openUserShiftDialog: (data: UserShiftDialogData) => void
    readonly openUserShiftEndExplanationDialog: (data: UserShiftEndExplanationDialogData) => void
    readonly openSplitUserShiftDialog: (data: SplitUserShiftDialogData) => void
    readonly registerDayUpdater: (day: number, updater: (shift: UserDayShift) => void) => void
    readonly updateDay: (shift: UserDayShift) => void
}

const ScheduleTableBodyDay: React.FunctionComponent<OwnProps> = ({
    scheduleId,
    date,
    showAll,
    isEditable,
    canShowHistory,
    canAddUserShifts,
    canEditUserShifts,
    canHideUserShifts,
    canEndUserShifts,
    user,
    dayColor,
    openScheduleShiftHistoryDialog,
    openUserShiftHistoryDialog,
    openDayDialog,
    openScheduleShiftDialog,
    openUserShiftDialog,
    openUserShiftEndExplanationDialog,
    openSplitUserShiftDialog,
    registerDayUpdater,
    updateDay,
}) => {
    const { appDispatch } = useStateDispatchContext()

    const [menuPosition, setMenuPosition] = useState<null | PopoverPosition>(null)
    const [scheduleShifts, setScheduleShifts] = useState<ScheduleUserDayShift[]>(
        user.days.filter((day: ScheduleUserDay) => day.day === date.date()).flatMap((day) => day.scheduleShifts)
    )
    const [userShifts, setUserShifts] = useState<UserDayShift[]>(
        user.days.filter((day: ScheduleUserDay) => day.day === date.date()).flatMap((day) => day.userShifts)
    )
    const [note, setNote] = useState<string | null>(
        user.days
            .filter((day: ScheduleUserDay) => day.day === date.date())
            .map((day) => day.note)
            .find(() => true) ?? null
    )

    const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>): void => {
        setMenuPosition({ top: event.clientY + 10, left: event.clientX + 10 })
    }, [])

    const closeMenu = useCallback(() => {
        setMenuPosition(null)
    }, [])

    const handleOpenAddScheduleShiftDialog = useCallback((): void => {
        setMenuPosition(null)

        openScheduleShiftDialog({
            scheduleId: scheduleId,
            date: date,
            user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
            shift: null,
            onSuccess: (shift) => {
                setScheduleShifts((value) => {
                    return value.concat([shift])
                })
            },
        })
    }, [scheduleId, date, user])

    const handleOpenEditScheduleShiftDialog = useCallback(
        (shift: ScheduleUserDayShift) => {
            openScheduleShiftDialog({
                scheduleId: scheduleId,
                date: date,
                user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
                shift: shift,
                onSuccess: (shift) => {
                    setScheduleShifts((value) => {
                        return value.map((value) => {
                            if (value.id === shift.id) {
                                return shift
                            }

                            return value
                        })
                    })
                },
            })
        },
        [scheduleId, date, user]
    )

    const handleOpenAddUserShiftDialog = useCallback((): void => {
        setMenuPosition(null)

        openUserShiftDialog({
            scheduleId: scheduleId,
            date: date,
            user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
            shift: null,
            onSuccess: (shift) => {
                setUserShifts((value) => {
                    return value.concat([shift])
                })
            },
        })
    }, [scheduleId, date, user])

    const handleOpenEditUserShiftDialog = useCallback(
        (shift: UserDayShift) => {
            openUserShiftDialog({
                scheduleId: scheduleId,
                date: date,
                user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
                shift: shift,
                onSuccess: (shift) => {
                    setUserShifts((value) => {
                        return value.map((value) => {
                            if (value.uuid === shift.uuid) {
                                return shift
                            }

                            return value
                        })
                    })
                },
            })
        },
        [scheduleId, date, user]
    )

    const handleOpenUserShiftEndExplanationDialog = useCallback(
        (shift: UserDayShift) => {
            openUserShiftEndExplanationDialog({
                scheduleId: scheduleId,
                user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
                shift: shift,
                onSuccess: (shift) => {
                    setUserShifts((value) => {
                        return value.map((value) => {
                            if (value.uuid === shift.uuid) {
                                return shift
                            }

                            return value
                        })
                    })
                },
            })
        },
        [scheduleId, user]
    )

    const handleShowScheduleShiftHistory = useCallback(
        (shift: ScheduleUserDayShift) => {
            appDispatch(setInProgress())
            API.schedule.shift
                .history(scheduleId, shift.id)
                .then((response) => {
                    openScheduleShiftHistoryDialog({
                        date: date,
                        user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
                        items: response,
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId]
    )

    const handleShowUserShiftHistory = useCallback(
        (shift: UserDayShift) => {
            appDispatch(setInProgress())
            API.shift
                .history(shift.uuid)
                .then((response) => {
                    openUserShiftHistoryDialog({
                        date: date,
                        user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
                        items: response,
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId]
    )

    const handleOpenSplitUserShiftDialog = useCallback(
        (shift: UserDayShift) => {
            openSplitUserShiftDialog({
                date: date,
                user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
                shift: shift,
                onSuccess: (splitShifts) => {
                    if (splitShifts.oldShift.edited!.start.getDay() !== splitShifts.newShift.edited!.start.getDay()) {
                        if (splitShifts.oldShift.edited!.start.getMonth() === splitShifts.newShift.edited!.start.getMonth()) {
                            updateDay(splitShifts.newShift)
                        }

                        setUserShifts((value) => {
                            return value.map((value) => {
                                if (value.uuid === splitShifts.oldShift.uuid) {
                                    return splitShifts.oldShift
                                }

                                return value
                            })
                        })
                    } else {
                        setUserShifts((value) => {
                            return value
                                .map((value) => {
                                    if (value.uuid === splitShifts.oldShift.uuid) {
                                        return [splitShifts.oldShift, splitShifts.newShift]
                                    }

                                    return [value]
                                })
                                .flat()
                        })
                    }
                },
            })
        },
        [scheduleId, date, user]
    )

    const handleOpenDayDialog = useCallback((): void => {
        setMenuPosition(null)

        openDayDialog({
            scheduleId: scheduleId,
            date: date,
            user: { id: user.id, fullName: `${user.firstName} ${user.lastName}` },
            note: note,
            onSuccess: (note) => {
                setNote(note)
            },
        })
    }, [scheduleId, date, user, note])

    const deleteShift = useCallback(
        (shift: ScheduleUserDayShift): void => {
            appDispatch(setInProgress())
            API.schedule.shift
                .delete(scheduleId, shift.id)
                .then((response) => {
                    setScheduleShifts((value) => {
                        if (showAll) {
                            return value.map((value) => (value.id === response.id ? response : value))
                        }

                        return value.filter((value) => value.id !== shift.id)
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId, showAll]
    )

    const restoreShift = useCallback(
        (shift: ScheduleUserDayShift): void => {
            appDispatch(setInProgress())
            API.schedule.shift
                .restore(scheduleId, shift.id)
                .then((response) => {
                    setScheduleShifts((value) => {
                        return value.map((value) => (value.id === response.id ? response : value))
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId]
    )

    const hideUserShift = useCallback(
        (shift: UserDayShift): void => {
            appDispatch(setInProgress())
            API.shift
                .hide(shift.uuid)
                .then((response) => {
                    setUserShifts((value) => {
                        if (showAll) {
                            return value.map((value) => (value.uuid === response.uuid ? response : value))
                        }

                        return value.filter((value) => value.uuid !== shift.uuid)
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId, showAll]
    )

    const restoreUserShift = useCallback(
        (shift: UserDayShift): void => {
            appDispatch(setInProgress())
            API.shift
                .restore(shift.uuid)
                .then((response) => {
                    setUserShifts((value) => {
                        return value.map((value) => (value.uuid === response.uuid ? response : value))
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId]
    )

    const endUserShift = useCallback(
        (shift: UserDayShift): void => {
            appDispatch(setInProgress())
            API.shift
                .end(shift.uuid)
                .then((response) => {
                    setUserShifts((value) => {
                        return value.map((value) => (value.uuid === response.uuid ? response : value))
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId]
    )

    const approveUserShift = useCallback(
        (shift: UserDayShift, flag: boolean): void => {
            appDispatch(setInProgress())

            const promise = flag ? API.shift.approve(shift.uuid) : API.shift.undoApprove(shift.uuid)

            promise
                .then((response) => {
                    setUserShifts((value) => {
                        return value.map((value) => (value.uuid === response.uuid ? response : value))
                    })

                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(handleApiError(error))
                    appDispatch(setFailure())
                })
        },
        [scheduleId]
    )

    const copyShift = useCallback(
        (shift: ScheduleUserDayShift): void => {
            const request = new ScheduleShiftRequest(
                user.id,
                date.date(),
                dayjs(shift.start).format('HH:mm'),
                dayjs(shift.end).format('HH:mm'),
                null,
                []
            )

            appDispatch(setInProgress())

            API.schedule.shift
                .create(scheduleId, request)
                .then((shift) => {
                    setScheduleShifts((value) => {
                        return value.concat([shift])
                    })
                    appDispatch(setSuccess())
                })
                .catch((error) => {
                    appDispatch(setFailure())
                    appDispatch(handleApiError(error))
                })
        },
        [scheduleId, user, date]
    )

    useEffect(() => {
        registerDayUpdater(date.date(), (shift: UserDayShift) => {
            setUserShifts((value) => {
                return value.concat(shift)
            })
        })
    }, [user])

    const [{ isOver, canDrop }, drop] = useDrop<ScheduleUserDayShift, unknown, { isOver: boolean; canDrop: boolean }>(
        () => ({
            accept: 'shift',
            drop: (item): unknown | undefined => {
                copyShift(item)

                return undefined
            },
            canDrop: (item): boolean =>
                isEditable &&
                scheduleShifts.find((value) => {
                    return value.id === item.id
                }) === undefined,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [isEditable, scheduleShifts]
    )

    const showAddButton = isEditable && (userShifts.length > 0 || scheduleShifts.length > 0) && (scheduleShifts.length < 4 || canAddUserShifts)
    const showNoteButton = showAddButton || note !== null

    return (
        <>
            <StyledTableCell
                ref={drop}
                dayColor={dayColor}
                isEmpty={scheduleShifts.length === 0 && userShifts.length === 0}
                onClick={isEditable && scheduleShifts.length === 0 && userShifts.length === 0 && note === null ? handleOpenMenu : undefined}
                sx={{ opacity: isOver && canDrop ? 0.4 : 1 }}
            >
                {scheduleShifts.length === 0 && userShifts.length === 0 && (
                    <StyledCenteredBox>
                        <Typography>---</Typography>
                    </StyledCenteredBox>
                )}

                {scheduleShifts.map((scheduleShift, index) => (
                    <ScheduleTableBodyDayShift
                        key={index}
                        isEditable={isEditable}
                        canShowHistory={canShowHistory}
                        shift={scheduleShift}
                        deleteShift={deleteShift}
                        restoreShift={restoreShift}
                        editShift={handleOpenEditScheduleShiftDialog}
                        showHistory={handleShowScheduleShiftHistory}
                    />
                ))}

                {userShifts.map((userShift, index) => (
                    <ScheduleTableBodyUserDayShift
                        key={index}
                        isEditable={isEditable}
                        canShowHistory={canShowHistory}
                        canEditUserShifts={canEditUserShifts}
                        canHideUserShifts={canHideUserShifts}
                        canEndUserShifts={canEndUserShifts}
                        shift={userShift}
                        editShift={handleOpenEditUserShiftDialog}
                        splitShift={handleOpenSplitUserShiftDialog}
                        hideShift={hideUserShift}
                        restoreShift={restoreUserShift}
                        endShift={endUserShift}
                        approveUserShift={approveUserShift}
                        showHistory={handleShowUserShiftHistory}
                        showShiftEndExplanation={handleOpenUserShiftEndExplanationDialog}
                    />
                ))}

                {(showAddButton || showNoteButton) && (
                    <StyledCenteredBox mb={1}>
                        {isEditable && scheduleShifts.filter((value) => value.isActive).length < 4 && (
                            <Tooltip title={strings.button.addScheduleShift}>
                                <IconButton onClick={handleOpenAddScheduleShiftDialog}>
                                    <AddScheduleShiftIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isEditable && canAddUserShifts && (
                            <Tooltip title={strings.button.addUserShift}>
                                <IconButton onClick={handleOpenAddUserShiftDialog}>
                                    <AddUserShiftIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isEditable && note === null && (
                            <Tooltip title={strings.button.addNote}>
                                <IconButton onClick={handleOpenDayDialog}>
                                    <EditNoteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {note !== null && (
                            <Tooltip title={note}>
                                <IconButton onClick={isEditable ? handleOpenDayDialog : undefined}>
                                    <NoteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </StyledCenteredBox>
                )}
            </StyledTableCell>

            <Menu
                open={menuPosition !== null}
                anchorPosition={menuPosition ?? undefined}
                anchorReference="anchorPosition"
                onClose={closeMenu}
                autoFocus={false}
            >
                <MenuItem onClick={handleOpenAddScheduleShiftDialog}>{strings.button.addScheduleShift}</MenuItem>
                {canAddUserShifts && <MenuItem onClick={handleOpenAddUserShiftDialog}>{strings.button.addUserShift}</MenuItem>}
                <MenuItem onClick={handleOpenDayDialog}>{strings.button.addNote}</MenuItem>
            </Menu>
        </>
    )
}

export default React.memo(ScheduleTableBodyDay)
