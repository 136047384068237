import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import _ from 'lodash'
import { StateContextType } from '../state/stateContext'
import { isErrorResponse } from './response'
import qs from 'qs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DateTime } from './DateTime'
import { ApiError } from './ApiError'
import localStore from '../state/LocalStore'

const client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Accept: 'application/json' },
    timeout: 25000,
})

dayjs.extend(utc)

const isoDateTimeFormat = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
const isoDateFormat = /^\d{4}-\d{2}-\d{2}$/

const isIsoDateTimeString = (value: any): boolean => value && typeof value === 'string' && isoDateTimeFormat.test(value)
const isIsoDateString = (value: any): boolean => value && typeof value === 'string' && isoDateFormat.test(value)

function handleDatesFromJson(body: any): void {
    if (body === null || body === undefined || typeof body !== 'object') {
        return
    }

    for (const key of Object.keys(body)) {
        const value = body[key]
        if (isIsoDateTimeString(value)) {
            body[key] = new DateTime(dayjs(value, { format: 'YYYY-MM-DD HH:mm:ss', utc: true }).toDate())
        } else if (isIsoDateString(value)) {
            body[key] = dayjs(value, { format: 'YYYY-MM-DD', utc: true }).toDate()
        } else if (typeof value === 'object') {
            handleDatesFromJson(value)
        }
    }
}

function handleDatesToJson(data: any): void {
    if (data === null || data === undefined || typeof data !== 'object') {
        return
    }

    for (const key of Object.keys(data)) {
        const value = data[key]
        if (value instanceof DateTime) {
            data[key] = dayjs.utc(data[key]).format('YYYY-MM-DD HH:mm:ss')
        } else if (value instanceof Date) {
            data[key] = dayjs(data[key]).format('YYYY-MM-DD')
        } else if (typeof value === 'object') {
            handleDatesToJson(value)
        }
    }
}

let requestAuthorizationInterceptor = -1
let requestSerializationInterceptor = -1
let responseSerializationInterceptor = -1
let responseRejectedInterceptor = -1

const configureRequestAuthorization = ({ sessionState }: StateContextType): void => {
    if (requestAuthorizationInterceptor !== -1) {
        client.interceptors.request.eject(requestAuthorizationInterceptor)
    }

    requestAuthorizationInterceptor = client.interceptors.request.use((config: AxiosRequestConfig) => {
        const { auth } = sessionState

        if (config.headers === null) {
            config.headers = {}
        }

        if (auth !== null) {
            if (!localStore.isLocked() || config.url === 'me/verify' || config.url === 'token/refresh') {
                config.headers!.Authorization = 'Bearer ' + auth.accessToken
            }
        }

        config.headers!['Cache-Control'] = 'no-cache'

        return config
    })
}

const configureRequestSerialization = (): void => {
    if (requestSerializationInterceptor !== -1) {
        client.interceptors.request.eject(requestSerializationInterceptor)
    }

    requestSerializationInterceptor = client.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            config.paramsSerializer = (params: any): string => {
                return qs.stringify(params, {
                    serializeDate: (date: Date) => {
                        if (date instanceof DateTime) {
                            return dayjs.utc(date).format('YYYY-MM-DD HH:mm:ss')
                        }

                        return dayjs(date).format('YYYY-MM-DD')
                    },
                })
            }

            const transformRequest = axios.defaults.transformRequest
            config.transformRequest = (data: any, headers: any): any => {
                handleDatesToJson(data)

                if (transformRequest !== null && transformRequest !== undefined) {
                    if (transformRequest instanceof Array) {
                        return transformRequest.reduce((acc, func): any => {
                            return func(acc, headers)
                        }, data)
                    }

                    return transformRequest(data, headers)
                }

                return data
            }

            return config
        },
        (error: AxiosError) => Promise.reject(error)
    )
}

const configureResponseSerialization = (): void => {
    if (responseSerializationInterceptor !== -1) {
        client.interceptors.response.eject(responseSerializationInterceptor)
    }

    responseSerializationInterceptor = client.interceptors.response.use((response: AxiosResponse) => {
        handleDatesFromJson(response.data)

        return response
    })
}

const configureResponseRejected = (): void => {
    if (responseRejectedInterceptor !== -1) {
        client.interceptors.response.eject(responseRejectedInterceptor)
    }

    responseRejectedInterceptor = client.interceptors.response.use(undefined, (error: AxiosError) => {
        let reason: ApiError = new ApiError(-1, null, error)

        if (error.message === 'Network Error') {
            // network error
        } else if (_.startsWith(error.message, 'timeout')) {
            // timeout error
        } else if (error.response !== undefined) {
            const { status, data } = error.response

            if (isErrorResponse(data)) {
                reason = new ApiError(status, data, error)
            } else {
                reason = new ApiError(status, null, error)
            }
        }

        return Promise.reject(reason)
    })
}

export const initializeClient = (): void => {
    configureRequestSerialization()
    configureResponseSerialization()
}

export const configureClient = (state: StateContextType): void => {
    configureResponseRejected()
    configureRequestAuthorization(state)
}

export default client
