import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
    Stack,
    styled,
    TextField,
} from '@mui/material'
import _, { toInteger } from 'lodash'
import Validator from 'validator'
import { API } from '../../api/api'
import { MenuItemType } from '../../model/MenuItemType'
import { changeMenuItem } from '../../state/menuItemActions'
import { setTitle } from '../../state/titleActions'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { routesDetails } from '../../routes/routesDetails'
import { MessageDisplayType, showMessage, showSnackbarMessage } from '../../state/messageActions'
import { Branch, branchLatLngLiteral, GeoPoint, geoPointLatLngLiteral, JobPosition, User, UserRole } from '../../api/response'
import { useStateDispatchContext } from '../../state/stateContext'
import { useStableNavigate } from '../../routes/StableNavigateContext'
import { useParams } from 'react-router'
import { handleApiError } from '../../state/apiErrorActions'
import dayjs, { Dayjs } from 'dayjs'
import { UserCreateRequest, UserEditRequest, UserHomeRequest } from '../../api/request'
import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'
import { AdvancedMarker, Map, MapMouseEvent, Pin, useMap } from '@vis.gl/react-google-maps'
import BusinessIcon from '@mui/icons-material/BusinessOutlined'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import { Circle } from '../../components/Circle'
import { useDebounce } from 'use-debounce'
import { fromAddress } from 'react-geocode'

const colors = {
    orange: { background: '#e16632', border: '#c04d1c' },
    white: '#ffffff',
}

const strings = {
    button: {
        refresh: 'Odśwież',
        save: 'Zapisz',
    },
    error: {
        branchRequired: 'Oddział jest wymagany',
        jobPositionRequired: 'Stanowisko jest wymagane',
        emailRequired: 'Adres email jest wymagany',
        emailLength: 'Wymagana wartość w przedziale <1, 255> znaków',
        emailInvalid: 'Proszę podać poprawny adres email',
        passwordRequired: 'Hasło jest wymagane',
        passwordLength: 'Minimalna długość to 8 znaków',
        rePasswordInvalid: 'Hasła nie są takie same',
        firstNameRequired: 'Imię jest wymagane',
        firstNameLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        lastNameRequired: 'Nazwisko jest wymagane',
        lastNameLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        phoneNumberRequired: 'Numer telefonu jest wymagany',
        phoneNumberInvalid: 'Proszę podać w formacie XXXXXXXXX, np. 500600700.',
        rateInvalid: 'Wymagana jest dodatnia liczba z dokładnością do dwóch miejsc po przecinku.',
        hiredAtRequired: 'Data zatrudnienia jest wymagana',
        firedAtInvalid: 'Podano błędną datę zwolnienia',
        rangeInvalid: 'Podano błędny zasięg',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
        homeLocation: 'Nie udało się odczytać współrzędnych geograficznych dla podanego adresu',
        homeLocationRequired: 'Wymagane jest wskazanie lokalizacji na mapie',
    },
    label: {
        email: 'Email',
        password: 'Hasło',
        rePassword: 'Powtórz hasło',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        phoneNumber: 'Telefon',
        rate: 'Stawka',
        useSmsNotifications: 'Używaj wiadomości SMS zamiast powiadomień Push',
        branch: 'Oddział',
        branchRange: 'Zasięg oddziału',
        branchesLoading: 'Wczytywanie oddziałów',
        jobPosition: 'Stanowisko',
        jobPositionsLoading: 'Wczytywanie stanowisk',
        roles: 'Prawa dostępu',
        mainPanelRoles: 'Główne funkcjonalności panelu',
        scheduleRoles: 'Prawa dostępu związane z harmonogramem',
        taskRoles: 'Prawa dostępu związane z zadaniami',
        appRoles: 'Funkcje w aplikacji mobilnej',
        hiredAt: 'Data zatrudnienia',
        firedAt: 'Data zwolnienia',
        homeAddress: 'Adres zamieszkania',
        homeRange: 'Zasięg miejsca zamieszkania',
    },
    message: {
        added: 'Nowy użytkownik została dodany',
        edited: 'Użytkownik został zaktualizowany',
    },
    role: {
        panel: 'Dostęp do panelu',
        manageUsers: 'Zarządzanie użytkownikami',
        showUserMap: 'Podgląd mapy użytkownika',
        resetUserPhone: 'Możliwość zresetowania zaufanego urządzenia',
        manageAdditionalBranches: 'Zarządzanie oddziałami dodatkowymi',
        manageCars: 'Zarządzanie samochodami',
        manageBranches: 'Zarządzanie oddziałami',
        manageStatuses: 'Zarządzanie statusami',
        manageHolidays: 'Zarządzanie świętami',
        manageJobPositions: 'Zarządzanie stanowiskami pracy',
        manageBranchSchedule: 'Zarządzanie harmonogramem własnego oddziału',
        manageUserBranchesSchedule: 'Zarządzanie harmonogramem oddziałów dodatkowych',
        manageAllSchedules: 'Zarządzanie harmonogramem wszystkich oddziałów',
        manageBranchUserShifts: 'Zarządzanie czasem pracy własnego oddziału',
        manageUserBranchesUserShifts: 'Zarządzanie czasem pracy oddziałów dodatkowych',
        manageAllUserShifts: 'Zarządzanie czasem pracy wszystkich oddziałów',
        viewBranchSchedules: 'Podgląd harmonogramu własnego oddziału',
        viewUserBranchesSchedules: 'Podgląd harmonogramu oddziałów dodatkowych',
        viewAllSchedules: 'Podgląd harmonogramu wszystkich oddziałów',
        manageForbiddenPlaces: 'Zarządzanie zabronionymi miejscami',
        managePastSchedule: 'Możliwość zmian harmonogramu w przeszłości',
        closeSchedule: 'Możliwość zamknięcia harmonogramu',
        manualAddUserShifts: 'Ręczne dodawanie czasów pracy',
        hideUserShifts: 'Możliwość ukrywania zarejestrowanych czasów pracy',
        endUserShifts: 'Możliwość ręcznego kończenia trwającego czasu pracy',
        exportUserSchedule: 'Możliwość eksportu harmonogramu użytkownika',
        showScheduleHistory: 'Możliwość podglądu historii zmian w harmonogramie',
        manageBranchTasks: 'Zarządzanie zadaniami własnego oddziału',
        manageAllTasks: 'Zarządzanie zadaniami wszystkich oddziałów',
        allowShiftsOutsideBranch: 'Możliwość rozpoczynania i kończenia czasu pracy poza oddziałem',
    },
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
}))

enum MapId {
    Branch = 'branchMap',
    Home = 'homeMap',
}

enum FormFieldNames {
    Branch = 'branch',
    BranchRange = 'branchRange',
    JobPosition = 'jobPosition',
    Email = 'email',
    Password = 'password',
    RePassword = 'rePassword',
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Rate = 'rate',
    UseSmsNotifications = 'useSmsNotifications',
    HiredAt = 'hiredAt',
    FiredAt = 'firedAt',
    HomeAddress = 'homeAddress',
    HomeRange = 'homeRange',
}

interface FormErrors {
    branch?: string
    branchRange?: string
    jobPosition?: string
    email?: string
    password?: string
    rePassword?: string
    firstName?: string
    lastName?: string
    phoneNumber?: string
    rate?: string
    hiredAt?: string
    firedAt?: string
    homeRange?: string
    homeAddress?: string
}

const UserDetailsPage: React.FunctionComponent = () => {
    const { appDispatch } = useStateDispatchContext()
    const navigate = useStableNavigate()
    const { id } = useParams()

    const map = useMap(MapId.Branch)
    const homeMap = useMap(MapId.Home)

    const [loading, setLoading] = useState<boolean>(false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [errors, setErrors] = useState<FormErrors>({})

    const [branch, setBranch] = useState<Branch | null>(null)
    const [branchRange, setBranchRange] = useState<string>('')
    const [jobPosition, setJobPosition] = useState<JobPosition | null>(null)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [rePassword, setRePassword] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [rate, setRate] = useState<string>('0.00')
    const [useSmsNotifications, setUseSmsNotifications] = useState<boolean>(false)
    const [roles, setRoles] = useState<UserRole[]>([])
    const [hiredAt, setHiredAt] = useState<Dayjs | null>(dayjs())
    const [firedAt, setFiredAt] = useState<Dayjs | null>(null)
    const [homeAddress, setHomeAddress] = useState<string>('')
    const [homeAddressChanged, setHomeAddressChanged] = useState<boolean>(false)
    const [homeRange, setHomeRange] = useState<string>('')
    const [homeLocation, setHomeLocation] = useState<GeoPoint | null>(null)

    const [branches, setBranches] = useState<Branch[]>([])
    const [loadingBranches, setLoadingBranches] = useState<boolean>(false)
    const [branchInput, setBranchInput] = useState<string | null>(null)
    const [jobPositions, setJobPositions] = useState<JobPosition[]>([])
    const [loadingJobPositions, setLoadingJobPositions] = useState<boolean>(false)
    const [jobPositionInput, setJobPositionInput] = useState<string | null>(null)

    const [homeAddressDebounced] = useDebounce(homeAddress, 1000)

    const userId = useMemo(() => {
        return parseInt(id ?? '0', 10)
    }, [id])

    const loadUser = useCallback(() => {
        if (userId === 0) {
            setLoaded(true)

            return
        }

        if (loading) {
            return
        }

        setLoading(true)
        setRefresh(false)
        API.users
            .details(userId)
            .then((user) => {
                setBranch(user.branch)
                setJobPosition(user.jobPosition)
                setEmail(user.email)
                setPassword('')
                setRePassword('')
                setFirstName(user.firstName)
                setLastName(user.lastName)
                setPhoneNumber(user.phoneNumber ?? '')
                setRate(user.rate)
                setBranchRange(user.branchRange !== null ? user.branchRange.toString() : '')
                setUseSmsNotifications(user.useSmsNotifications)
                setRoles(user.roles)
                setHiredAt(dayjs(user.hiredAt))
                setFiredAt(user.firedAt !== null ? dayjs(user.firedAt) : null)
                const userHome = user.home
                setHomeAddressChanged(false)
                if (userHome !== null) {
                    setHomeAddress(userHome.address)
                    setHomeRange(userHome.range.toString())
                    setHomeLocation(userHome.location)
                } else {
                    setHomeAddress('')
                    setHomeRange('')
                    setHomeLocation(null)
                }

                setLoaded(true)
            })
            .catch((error) => {
                setRefresh(true)
                appDispatch(handleApiError(error))
            })
            .finally(() => {
                setLoading(false)
            })
    }, [userId, loading])

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Password: {
                setPassword(event.target.value)

                break
            }
            case FormFieldNames.RePassword: {
                setRePassword(event.target.value)

                break
            }
            case FormFieldNames.BranchRange: {
                setBranchRange(event.target.value)

                break
            }
            case FormFieldNames.Email: {
                setEmail(event.target.value)

                break
            }
            case FormFieldNames.FirstName: {
                setFirstName(event.target.value)

                break
            }
            case FormFieldNames.LastName: {
                setLastName(event.target.value)

                break
            }
            case FormFieldNames.PhoneNumber: {
                setPhoneNumber(event.target.value)

                break
            }
            case FormFieldNames.Rate: {
                setRate(event.target.value)

                break
            }
            case FormFieldNames.HomeAddress: {
                setHomeAddressChanged(true)
                setHomeAddress(event.target.value)

                break
            }
            case FormFieldNames.HomeRange: {
                setHomeRange(event.target.value)

                break
            }
        }
    }, [])

    const onHomeMapClick = useCallback((event: MapMouseEvent): void => {
        const latLng = event.detail.latLng
        if (latLng === null) {
            return
        }

        setHomeLocation({ latitude: latLng.lat, longitude: latLng.lng })
    }, [])

    useEffect(() => {
        if (homeAddressDebounced.length > 3 && homeAddressChanged) {
            fromAddress(homeAddressDebounced)
                .then(({ results }) => {
                    if (results.length > 0) {
                        const { lat, lng } = results[0].geometry.location
                        setHomeLocation({ latitude: lat, longitude: lng })
                        homeMap?.panTo({ lat, lng })
                    } else {
                        appDispatch(showMessage({ message: strings.error.homeLocation, type: MessageDisplayType.Snackbar }))
                    }
                })
                .catch(() => {
                    appDispatch(showMessage({ message: strings.error.homeLocation, type: MessageDisplayType.Snackbar }))
                })
        }
    }, [homeAddressDebounced, homeAddressChanged])

    const onCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        switch (event.currentTarget.name) {
            case FormFieldNames.UseSmsNotifications: {
                setUseSmsNotifications(checked)

                break
            }
        }
    }, [])

    const onHiredAtDateChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setHiredAt(value)
        } else {
            setHiredAt(null)
        }
    }, [])

    const onFiredAtDateChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setFiredAt(value)
        } else {
            setFiredAt(null)
        }
    }, [])

    const onRoleCheckboxChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            if (checked) {
                let newRoles = roles.concat([event.target.value as UserRole])

                if (newRoles.includes(UserRole.ManageBranchSchedules)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ViewBranchSchedules)
                }

                if (newRoles.includes(UserRole.ManageUserBranchesSchedules)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ViewUserBranchesSchedules)
                }

                if (newRoles.includes(UserRole.ManageAllSchedules)) {
                    newRoles = newRoles.filter(
                        (value) =>
                            value !== UserRole.ViewAllSchedules &&
                            value !== UserRole.ViewUserBranchesSchedules &&
                            value !== UserRole.ManageUserBranchesSchedules
                    )
                }

                if (newRoles.includes(UserRole.ViewAllSchedules)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ViewUserBranchesSchedules)
                }

                if (newRoles.includes(UserRole.ManageAllUserShifts)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ManageUserBranchesUserShifts)
                }

                setRoles(newRoles)
            } else {
                let newRoles = roles.filter((value) => value !== event.target.value)

                if (!newRoles.includes(UserRole.Panel)) {
                    newRoles = newRoles.filter(
                        (value) =>
                            value !== UserRole.ManageUsers &&
                            value !== UserRole.ManageCars &&
                            value !== UserRole.ManageBranches &&
                            value !== UserRole.ManageJobPositions &&
                            value !== UserRole.ManageStatuses &&
                            value !== UserRole.ManageHolidays &&
                            value !== UserRole.ManageForbiddenPlaces &&
                            value !== UserRole.ManageBranchSchedules &&
                            value !== UserRole.ViewBranchSchedules &&
                            value !== UserRole.ManageBranchTasks
                    )
                }

                if (!newRoles.includes(UserRole.ManageUsers)) {
                    newRoles = newRoles.filter(
                        (value) => value !== UserRole.ShowUserMap && value !== UserRole.ResetUserPhone && value !== UserRole.ManageAdditionalBranches
                    )
                }

                if (!newRoles.includes(UserRole.ManageBranchSchedules)) {
                    newRoles = newRoles.filter(
                        (value) =>
                            value !== UserRole.ManageUserBranchesSchedules &&
                            value !== UserRole.ManageAllSchedules &&
                            value !== UserRole.ManagePastSchedules &&
                            value !== UserRole.CloseSchedules &&
                            value !== UserRole.ManageBranchUserShifts &&
                            value !== UserRole.ExportUserSchedule &&
                            value !== UserRole.ShowScheduleHistory
                    )
                }

                if (!newRoles.includes(UserRole.ManageUserBranchesSchedules)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ManageUserBranchesUserShifts)
                }

                if (!newRoles.includes(UserRole.ManageAllSchedules)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ManageAllUserShifts)
                }

                if (!newRoles.includes(UserRole.ViewBranchSchedules)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ViewUserBranchesSchedules && value !== UserRole.ViewAllSchedules)
                }

                if (!newRoles.includes(UserRole.ManageBranchUserShifts)) {
                    newRoles = newRoles.filter(
                        (value) =>
                            value !== UserRole.ManageAllUserShifts &&
                            value !== UserRole.ManageUserBranchesUserShifts &&
                            value !== UserRole.ManualAddUserShifts &&
                            value !== UserRole.HideUserShifts &&
                            value !== UserRole.EndUserShifts
                    )
                }

                if (!newRoles.includes(UserRole.ManageBranchTasks)) {
                    newRoles = newRoles.filter((value) => value !== UserRole.ManageAllTasks)
                }

                setRoles(newRoles)
            }
        },
        [roles]
    )

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (userId === 0) {
            if (Validator.isEmpty(email)) {
                errors.email = strings.error.emailRequired
            } else if (!Validator.isEmail(email)) {
                errors.email = strings.error.emailInvalid
            } else if (!Validator.isLength(email, { min: 1, max: 255 })) {
                errors.email = strings.error.emailLength
            }

            if (Validator.isEmpty(password)) {
                errors.password = strings.error.passwordRequired
            } else if (!Validator.isLength(password, { min: 8 })) {
                errors.password = strings.error.passwordLength
            } else if (password !== rePassword) {
                errors.rePassword = strings.error.rePasswordInvalid
            }
        }

        if (branch === null) {
            errors.branch = strings.error.branchRequired
        }

        // eslint-disable-next-line camelcase
        if (!Validator.isEmpty(branchRange) && !Validator.isNumeric(branchRange, { no_symbols: true })) {
            errors.branchRange = strings.error.rangeInvalid
        } else if (!Validator.isEmpty(branchRange)) {
            const intValue = toInteger(branchRange)
            if (intValue <= 0) {
                errors.branchRange = strings.error.rangeInvalid
            }
        }

        if (jobPosition === null) {
            errors.jobPosition = strings.error.jobPositionRequired
        }

        if (Validator.isEmpty(firstName)) {
            errors.firstName = strings.error.firstNameRequired
        } else if (!Validator.isLength(firstName, { min: 1, max: 128 })) {
            errors.firstName = strings.error.firstNameLength
        }

        if (Validator.isEmpty(lastName)) {
            errors.lastName = strings.error.lastNameRequired
        } else if (!Validator.isLength(lastName, { min: 1, max: 128 })) {
            errors.lastName = strings.error.lastNameLength
        }

        if (Validator.isEmpty(phoneNumber)) {
            errors.phoneNumber = strings.error.phoneNumberRequired
        } else if (phoneNumber.match(/^\d{9}$/g) === null) {
            errors.phoneNumber = strings.error.phoneNumberInvalid
        }

        if (rate.match(/^\d+(\.\d{1,2})?$/g) === null) {
            errors.rate = strings.error.rateInvalid
        }

        if (hiredAt === null) {
            errors.hiredAt = strings.error.hiredAtRequired
        }

        if (hiredAt !== null && firedAt !== null && firedAt.isBefore(hiredAt, 'D')) {
            errors.firedAt = strings.error.firedAtInvalid
        }

        let homeRequest: UserHomeRequest | null = null
        if (homeAddress.length > 0) {
            const homeRangeValue = toInteger(homeRange)
            // eslint-disable-next-line camelcase
            if (Validator.isEmpty(homeRange) || !Validator.isNumeric(homeRange, { no_symbols: true }) || homeRangeValue <= 0) {
                errors.homeRange = strings.error.rangeInvalid
            }

            if (homeLocation === null || !Validator.isLatLong(`${homeLocation.latitude},${homeLocation.longitude}`)) {
                errors.homeAddress = strings.error.homeLocationRequired
            } else if (!errors.homeRange) {
                homeRequest = new UserHomeRequest(homeAddress, toInteger(homeRange), homeLocation.latitude, homeLocation.longitude)
            }
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        appDispatch(setInProgress())

        const promise: Promise<User> =
            userId !== 0
                ? API.users.edit(
                      userId,
                      new UserEditRequest(
                          branch!.id,
                          jobPosition!.id,
                          firstName,
                          lastName,
                          phoneNumber,
                          rate,
                          branchRange.length > 0 ? toInteger(branchRange) : null,
                          useSmsNotifications,
                          roles,
                          hiredAt!.toDate(),
                          firedAt !== null ? firedAt.toDate() : null,
                          homeRequest
                      )
                  )
                : API.users.add(
                      new UserCreateRequest(
                          branch!.id,
                          jobPosition!.id,
                          email,
                          password,
                          firstName,
                          lastName,
                          phoneNumber,
                          rate,
                          branchRange.length > 0 ? toInteger(branchRange) : null,
                          useSmsNotifications,
                          roles,
                          hiredAt!.toDate(),
                          homeRequest
                      )
                  )

        promise
            .then((response) => {
                appDispatch(setSuccess())

                if (userId === 0) {
                    appDispatch(showSnackbarMessage(strings.message.added))
                    navigate(routesDetails.authenticated.user.to(response.id), { replace: true })
                } else {
                    appDispatch(showSnackbarMessage(strings.message.edited))
                }
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))

                if (error === null) {
                    appDispatch(showSnackbarMessage(strings.error.unknown))
                }
            })
    }

    useEffect(() => {
        setLoadingBranches(true)
        API.users
            .branches()
            .then((response) => {
                setBranches(response)
            })
            .finally(() => {
                setLoadingBranches(false)
            })
    }, [])

    useEffect(() => {
        setLoadingJobPositions(true)
        API.users
            .jobPositions()
            .then((response) => {
                setJobPositions(response)
            })
            .finally(() => {
                setLoadingJobPositions(false)
            })
    }, [])

    useEffect(() => {
        appDispatch(changeMenuItem(MenuItemType.Users))
        appDispatch(setTitle(MenuItemType.Users))
    }, [])

    useEffect(() => {
        const number = parseInt(id ?? '0', 10)
        if (isNaN(number)) {
            navigate(routesDetails.authenticated.user.to(0), { replace: true })
        } else {
            loadUser()
        }
    }, [])

    useEffect(() => {
        if (branch !== null) {
            map?.panTo(branchLatLngLiteral(branch))
        }
    }, [branch])

    return (
        <StyledPaper elevation={2}>
            {loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Stack>
            )}

            {refresh && !loading && (
                <Stack
                    direction="row"
                    justifyContent="center"
                >
                    <Button
                        onClick={loadUser}
                        variant="contained"
                    >
                        {strings.button.refresh}
                    </Button>
                </Stack>
            )}

            {loaded && (
                <Stack
                    direction="column"
                    spacing={2}
                    ml={3}
                    mr={3}
                >
                    <TextField
                        id={FormFieldNames.Email}
                        name={FormFieldNames.Email}
                        value={email}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.email}
                        margin="dense"
                        onChange={onTextFieldChange}
                        helperText={errors.email}
                        error={errors.email !== undefined}
                        inputProps={{ maxLength: 255 }}
                    />

                    {userId === 0 && (
                        <>
                            <TextField
                                id={FormFieldNames.Password}
                                name={FormFieldNames.Password}
                                value={password}
                                multiline={false}
                                required={true}
                                fullWidth={true}
                                label={strings.label.password}
                                margin="dense"
                                type="password"
                                onChange={onTextFieldChange}
                                helperText={errors.password}
                                error={errors.password !== undefined}
                            />

                            <TextField
                                id={FormFieldNames.RePassword}
                                name={FormFieldNames.RePassword}
                                value={rePassword}
                                multiline={false}
                                required={true}
                                fullWidth={true}
                                label={strings.label.rePassword}
                                margin="dense"
                                type="password"
                                onChange={onTextFieldChange}
                                helperText={errors.rePassword}
                                error={errors.rePassword !== undefined}
                            />
                        </>
                    )}

                    <TextField
                        id={FormFieldNames.HomeAddress}
                        name={FormFieldNames.HomeAddress}
                        value={homeAddress}
                        multiline={false}
                        required={false}
                        fullWidth={true}
                        label={strings.label.homeAddress}
                        margin="dense"
                        onChange={onTextFieldChange}
                        inputProps={{ maxLength: 255 }}
                        helperText={errors.homeAddress}
                        error={errors.homeAddress !== undefined}
                    />

                    {!!homeAddress && homeAddress.length > 0 && (
                        <>
                            <TextField
                                id={FormFieldNames.HomeRange}
                                name={FormFieldNames.HomeRange}
                                value={homeRange}
                                multiline={false}
                                required={true}
                                fullWidth={true}
                                label={strings.label.homeRange}
                                margin="none"
                                type="number"
                                onChange={onTextFieldChange}
                                helperText={errors.homeRange}
                                error={errors.homeRange !== undefined}
                            />

                            <Map
                                id={MapId.Home}
                                defaultZoom={16}
                                defaultCenter={homeLocation !== null ? geoPointLatLngLiteral(homeLocation) : { lat: 52.175165, lng: 20.99861 }}
                                gestureHandling={'greedy'}
                                style={{ width: '100%', height: 400 }}
                                mapId="7a0c8b575cfb7279"
                                onClick={onHomeMapClick}
                            >
                                {!!homeLocation && (
                                    <AdvancedMarker
                                        position={geoPointLatLngLiteral(homeLocation)}
                                        title={homeAddress}
                                    >
                                        <Pin
                                            background={colors.orange.background}
                                            borderColor={colors.orange.border}
                                            glyphColor={colors.white}
                                        >
                                            <HomeIcon style={{ width: 16 }} />
                                        </Pin>
                                    </AdvancedMarker>
                                )}

                                {!!homeLocation && !!homeRange && (
                                    <Circle
                                        radius={parseInt(homeRange)}
                                        center={geoPointLatLngLiteral(homeLocation)}
                                        strokeColor={'#0c4cb3'}
                                        strokeOpacity={1}
                                        strokeWeight={3}
                                        clickable={false}
                                        fillColor={'#3b82f6'}
                                        fillOpacity={0.3}
                                    />
                                )}
                            </Map>
                        </>
                    )}

                    <Autocomplete
                        id={FormFieldNames.Branch}
                        disablePortal={true}
                        loading={loadingBranches}
                        loadingText={strings.label.branchesLoading}
                        options={branches}
                        isOptionEqualToValue={(option, value): boolean => option.id === value.id}
                        inputValue={branchInput ?? ''}
                        value={branch}
                        onInputChange={(_event, newInputValue): void => {
                            setBranchInput(newInputValue)
                        }}
                        onChange={(_event, newValue): void => {
                            setBranch(newValue)
                        }}
                        getOptionLabel={(option): string => option.name}
                        renderInput={(params): React.ReactNode => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                margin="dense"
                                error={errors.branch !== undefined}
                                helperText={errors.branch}
                                required={true}
                                label={strings.label.branch}
                            />
                        )}
                    />

                    <TextField
                        id={FormFieldNames.BranchRange}
                        name={FormFieldNames.BranchRange}
                        value={branchRange}
                        multiline={false}
                        required={false}
                        fullWidth={true}
                        label={strings.label.branchRange}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.branchRange}
                        error={errors.branchRange !== undefined}
                    />

                    <Map
                        id={MapId.Branch}
                        defaultZoom={16}
                        defaultCenter={branch !== null ? branchLatLngLiteral(branch) : { lat: 52.175165, lng: 20.99861 }}
                        gestureHandling={'greedy'}
                        style={{ width: '100%', height: 400 }}
                        mapId="7a0c8b575cfb7279"
                    >
                        {!!branch && (
                            <AdvancedMarker
                                position={branchLatLngLiteral(branch)}
                                title={branch.name}
                            >
                                <Pin
                                    background={colors.orange.background}
                                    borderColor={colors.orange.border}
                                    glyphColor={colors.white}
                                >
                                    <BusinessIcon style={{ width: 16 }} />
                                </Pin>
                            </AdvancedMarker>
                        )}

                        {!!branch && (
                            <Circle
                                radius={branch.range}
                                center={branchLatLngLiteral(branch)}
                                strokeColor={'#0c4cb3'}
                                strokeOpacity={1}
                                strokeWeight={3}
                                clickable={false}
                                fillColor={'#3b82f6'}
                                fillOpacity={0.3}
                            />
                        )}

                        {!!branch && branchRange.length > 0 && (
                            <Circle
                                radius={parseInt(branchRange)}
                                center={branchLatLngLiteral(branch)}
                                strokeColor={'#681e16'}
                                strokeOpacity={1}
                                strokeWeight={3}
                                clickable={false}
                                fillColor={'#82261b'}
                                fillOpacity={0.3}
                            />
                        )}
                    </Map>

                    <Autocomplete
                        id={FormFieldNames.JobPosition}
                        disablePortal={true}
                        loading={loadingJobPositions}
                        loadingText={strings.label.jobPositionsLoading}
                        options={jobPositions}
                        isOptionEqualToValue={(option, value): boolean => option.id === value.id}
                        inputValue={jobPositionInput ?? ''}
                        value={jobPosition}
                        onInputChange={(_event, newInputValue): void => {
                            setJobPositionInput(newInputValue)
                        }}
                        onChange={(_event, newValue): void => {
                            setJobPosition(newValue)
                        }}
                        getOptionLabel={(option): string => option.name}
                        renderInput={(params): React.ReactNode => (
                            <TextField
                                {...params}
                                fullWidth={true}
                                margin="dense"
                                error={errors.jobPosition !== undefined}
                                helperText={errors.jobPosition}
                                required={true}
                                label={strings.label.jobPosition}
                            />
                        )}
                    />

                    <TextField
                        id={FormFieldNames.FirstName}
                        name={FormFieldNames.FirstName}
                        value={firstName}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.firstName}
                        margin="dense"
                        onChange={onTextFieldChange}
                        helperText={errors.firstName}
                        error={errors.firstName !== undefined}
                        inputProps={{ maxLength: 128 }}
                    />

                    <TextField
                        id={FormFieldNames.LastName}
                        name={FormFieldNames.LastName}
                        value={lastName}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.lastName}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.lastName}
                        error={errors.lastName !== undefined}
                        inputProps={{ maxLength: 128 }}
                    />

                    <TextField
                        id={FormFieldNames.PhoneNumber}
                        name={FormFieldNames.PhoneNumber}
                        value={phoneNumber}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.phoneNumber}
                        margin="none"
                        onChange={onTextFieldChange}
                        helperText={errors.phoneNumber}
                        error={errors.phoneNumber !== undefined}
                        inputProps={{ maxLength: 24 }}
                    />

                    <TextField
                        id={FormFieldNames.Rate}
                        name={FormFieldNames.Rate}
                        value={rate}
                        multiline={false}
                        required={true}
                        fullWidth={true}
                        label={strings.label.rate}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                        helperText={errors.rate}
                        error={errors.rate !== undefined}
                        inputProps={{ maxLength: 16 }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={FormFieldNames.UseSmsNotifications}
                                checked={useSmsNotifications}
                                onChange={onCheckboxChange}
                            />
                        }
                        label={strings.label.useSmsNotifications}
                    />

                    <DatePicker
                        label={strings.label.hiredAt}
                        format="YYYY-MM-DD"
                        value={hiredAt}
                        slotProps={{
                            textField: {
                                helperText: errors.hiredAt,
                                error: errors.hiredAt !== undefined,
                            },
                        }}
                        onChange={onHiredAtDateChange}
                    />

                    {!!userId && (
                        <DatePicker
                            label={strings.label.firedAt}
                            format="YYYY-MM-DD"
                            value={firedAt}
                            slotProps={{
                                textField: {
                                    helperText: errors.firedAt,
                                    error: errors.firedAt !== undefined,
                                },
                            }}
                            onChange={onFiredAtDateChange}
                        />
                    )}

                    <FormGroup>
                        <FormLabel component="legend">{strings.label.roles}</FormLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.Panel)}
                                    value={UserRole.Panel}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.panel}
                        />

                        <br />
                        <FormLabel component="span">{strings.label.mainPanelRoles}</FormLabel>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageUsers)}
                                    value={UserRole.ManageUsers}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageUsers}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ShowUserMap)}
                                    value={UserRole.ShowUserMap}
                                    disabled={!roles.includes(UserRole.ManageUsers)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.showUserMap}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ResetUserPhone)}
                                    value={UserRole.ResetUserPhone}
                                    disabled={!roles.includes(UserRole.ManageUsers)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.resetUserPhone}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageAdditionalBranches)}
                                    value={UserRole.ManageAdditionalBranches}
                                    disabled={!roles.includes(UserRole.ManageUsers)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageAdditionalBranches}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageBranches)}
                                    value={UserRole.ManageBranches}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageBranches}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageJobPositions)}
                                    value={UserRole.ManageJobPositions}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageJobPositions}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageStatuses)}
                                    value={UserRole.ManageStatuses}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageStatuses}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageCars)}
                                    value={UserRole.ManageCars}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageCars}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageHolidays)}
                                    value={UserRole.ManageHolidays}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageHolidays}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageForbiddenPlaces)}
                                    value={UserRole.ManageForbiddenPlaces}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageForbiddenPlaces}
                        />

                        <br />
                        <FormLabel component="span">{strings.label.scheduleRoles}</FormLabel>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ViewBranchSchedules)}
                                    value={UserRole.ViewBranchSchedules}
                                    disabled={!roles.includes(UserRole.Panel) || roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.viewBranchSchedules}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ViewUserBranchesSchedules)}
                                    value={UserRole.ViewUserBranchesSchedules}
                                    disabled={
                                        (!roles.includes(UserRole.ViewBranchSchedules) && !roles.includes(UserRole.ManageBranchSchedules)) ||
                                        roles.includes(UserRole.ViewAllSchedules) ||
                                        roles.includes(UserRole.ManageUserBranchesSchedules) ||
                                        roles.includes(UserRole.ManageAllSchedules)
                                    }
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.viewUserBranchesSchedules}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ViewAllSchedules)}
                                    value={UserRole.ViewAllSchedules}
                                    disabled={
                                        (!roles.includes(UserRole.ViewBranchSchedules) && !roles.includes(UserRole.ManageBranchSchedules)) ||
                                        roles.includes(UserRole.ManageAllSchedules)
                                    }
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.viewAllSchedules}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageBranchSchedules)}
                                    value={UserRole.ManageBranchSchedules}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageBranchSchedule}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageUserBranchesSchedules)}
                                    value={UserRole.ManageUserBranchesSchedules}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules) || roles.includes(UserRole.ManageAllSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageUserBranchesSchedule}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageAllSchedules)}
                                    value={UserRole.ManageAllSchedules}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageAllSchedules}
                        />

                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManagePastSchedules)}
                                    value={UserRole.ManagePastSchedules}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.managePastSchedule}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.CloseSchedules)}
                                    value={UserRole.CloseSchedules}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.closeSchedule}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ExportUserSchedule)}
                                    value={UserRole.ExportUserSchedule}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.exportUserSchedule}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ShowScheduleHistory)}
                                    value={UserRole.ShowScheduleHistory}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.showScheduleHistory}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageBranchUserShifts)}
                                    value={UserRole.ManageBranchUserShifts}
                                    disabled={!roles.includes(UserRole.ManageBranchSchedules)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageBranchUserShifts}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageUserBranchesUserShifts)}
                                    value={UserRole.ManageUserBranchesUserShifts}
                                    disabled={
                                        (!roles.includes(UserRole.ManageUserBranchesSchedules) && !roles.includes(UserRole.ManageAllSchedules)) ||
                                        !roles.includes(UserRole.ManageBranchUserShifts) ||
                                        roles.includes(UserRole.ManageAllUserShifts)
                                    }
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageUserBranchesUserShifts}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageAllUserShifts)}
                                    value={UserRole.ManageAllUserShifts}
                                    disabled={!roles.includes(UserRole.ManageAllSchedules) || !roles.includes(UserRole.ManageBranchUserShifts)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageAllUserShifts}
                        />

                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManualAddUserShifts)}
                                    value={UserRole.ManualAddUserShifts}
                                    disabled={!roles.includes(UserRole.ManageBranchUserShifts)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manualAddUserShifts}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.HideUserShifts)}
                                    value={UserRole.HideUserShifts}
                                    disabled={!roles.includes(UserRole.ManageBranchUserShifts)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.hideUserShifts}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 1 }}
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.EndUserShifts)}
                                    value={UserRole.EndUserShifts}
                                    disabled={!roles.includes(UserRole.ManageBranchUserShifts)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.endUserShifts}
                        />

                        <br />
                        <FormLabel component="span">{strings.label.taskRoles}</FormLabel>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageBranchTasks)}
                                    value={UserRole.ManageBranchTasks}
                                    disabled={!roles.includes(UserRole.Panel)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageBranchTasks}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.ManageAllTasks)}
                                    value={UserRole.ManageAllTasks}
                                    disabled={!roles.includes(UserRole.ManageBranchTasks)}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.manageAllTasks}
                        />

                        <br />
                        <FormLabel component="span">{strings.label.appRoles}</FormLabel>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={roles.includes(UserRole.AllowShiftsOutsideBranch)}
                                    value={UserRole.AllowShiftsOutsideBranch}
                                    onChange={onRoleCheckboxChange}
                                />
                            }
                            label={strings.role.allowShiftsOutsideBranch}
                        />
                    </FormGroup>

                    <Stack
                        direction="row"
                        justifyContent="left"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                        >
                            {strings.button.save}
                        </Button>
                    </Stack>
                </Stack>
            )}
        </StyledPaper>
    )
}

export default React.memo(UserDetailsPage)
