import { AccessToken, Account } from '../api/response'

enum LocalStorageKey {
    Auth = 'Auth',
    Account = 'Account',
    Locked = 'Locked',
}

class LocalStore {
    constructor() {
        const locked = localStorage.getItem(LocalStorageKey.Locked)
        if (locked !== null) {
            this.clearStore()
        }
    }

    public clearStore = (): void => {
        try {
            localStorage.clear()
        } catch (error) {
            // Ignore
        }
    }

    public storeAccessToken = (auth: AccessToken): void => {
        this.setItem(LocalStorageKey.Auth, auth)
    }

    public storeAccount = (account: Account): void => {
        this.setItem(LocalStorageKey.Account, account)
    }

    public removeAccessToken = (): void => {
        this.removeItem(LocalStorageKey.Auth)
    }

    public removeAccount = (): void => {
        this.removeItem(LocalStorageKey.Account)
    }

    public lock = (): void => {
        this.setItem(LocalStorageKey.Locked, true)
    }

    public unlock = (): void => {
        this.removeItem(LocalStorageKey.Locked)
    }

    public isLocked = (): boolean => {
        try {
            const locked = localStorage.getItem(LocalStorageKey.Locked)
            if (locked !== null) {
                return true
            }
        } catch (error) {
            //
        }

        return false
    }

    public accessToken = (): AccessToken | null => {
        try {
            const json = localStorage.getItem(LocalStorageKey.Auth)
            if (json) {
                const accessToken = JSON.parse(json) as AccessToken

                if (!accessToken.refreshToken) {
                    return null
                }

                return accessToken
            }

            return null
        } catch (error) {
            return null
        }
    }

    public account = (): Account | null => {
        try {
            const json = localStorage.getItem(LocalStorageKey.Account)
            if (json) {
                return JSON.parse(json)
            }

            return null
        } catch (error) {
            return null
        }
    }

    private setItem = (key: LocalStorageKey, value: any): void => {
        try {
            localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            // Ignore
        }
    }

    private removeItem = (key: LocalStorageKey): void => {
        try {
            localStorage.removeItem(key)
        } catch (error) {
            // Ignore
        }
    }
}

const localStore = new LocalStore()

export default localStore
