import React, { useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material'
import { useCallback } from 'react'
import { UserBranch, Branch, UserBranchAccess } from '../../api/response'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, DateValidationError } from '@mui/x-date-pickers'
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals'
import { API } from '../../api/api'
import _ from 'lodash'
import { useStateDispatchContext } from '../../state/stateContext'
import { setFailure, setInProgress, setSuccess } from '../../state/progressActions'
import { handleApiError } from '../../state/apiErrorActions'
import { UserBranchRequest } from '../../api/request'

const strings = {
    label: {
        title: (editing: boolean): string => (editing ? 'Edycja' : 'Dodawanie'),
        start: 'Start',
        end: 'Koniec',
        branch: 'Oddział',
        indefinitely: 'Bezterminowo',
        manageBranchSchedules: 'Zarządzanie harmonogramem',
        manageBranchUserShifts: 'Zarządzenie czasem pracy',
        viewBranchSchedules: 'Podgląd harmonogramu',
        access: 'Dostęp do oddziału:',
    },
    button: {
        cancel: 'Anuluj',
        save: 'Zapisz',
    },
    error: {
        branchRequired: 'Proszę wybrać oddział',
        startInvalid: 'Proszę podać poprawną datę początku okresu',
        endInvalid: 'Proszę podać poprawną datę końca okresu',
        pastDateInvalid: 'Nie można ustawić daty w przeszłości',
        endBeforeStartInvalid: 'Data końca nie może być wcześniej niż data początku',
    },
}

enum FormFieldNames {
    Branch = 'branch',
    Start = 'start',
    End = 'end',
    Indefinitely = 'indefinitely',
    ManageBranchSchedules = 'manageBranchSchedules',
    ManageBranchUserShifts = 'manageBranchUserShifts',
    ViewBranchSchedules = 'viewBranchSchedules',
}

interface FormErrors {
    branch?: string
    start?: string
    end?: string
}

export interface UserBranchDialogData {
    userId: number
    userBranch: UserBranch | null
    onSuccess: (userBranch: UserBranch) => void
}

interface OwnProps {
    readonly open: boolean
    readonly data: UserBranchDialogData
    readonly closeDialog: () => void
}

const UserBranchDialog: React.FunctionComponent<OwnProps> = ({ open, data, closeDialog }) => {
    const { userId, userBranch, onSuccess } = data

    const [branches, setBranches] = useState<Branch[]>([])
    const [errors, setErrors] = useState<FormErrors>({})
    const [branchId, setBranchId] = useState<number | null>(null)
    const [start, setStart] = useState<Dayjs>(dayjs())
    const [end, setEnd] = useState<Dayjs | null>(dayjs())
    const [indefinitely, setIndefinitely] = useState<boolean>(false)
    const [startInvalid, setStartInvalid] = useState<boolean>(false)
    const [endInvalid, setEndInvalid] = useState<boolean>(false)
    const [manageBranchSchedules, setManageBranchSchedules] = useState<boolean>(false)
    const [manageBranchUserShifts, setManageBranchUserShifts] = useState<boolean>(false)
    const [viewBranchSchedules, setViewBranchSchedules] = useState<boolean>(false)

    const { appDispatch } = useStateDispatchContext()

    useEffect(() => {
        API.userBranches.branches(userId).then((response) => {
            setBranches(response)
        })
    }, [userId])

    useEffect(() => {
        const userBranch = data.userBranch

        if (open && userBranch !== null) {
            setBranchId(userBranch.branch.id)
            setStart(dayjs(userBranch.start))
            if (userBranch.end !== null) {
                setEnd(dayjs(userBranch.end))
                setIndefinitely(false)
            } else {
                setEnd(null)
                setIndefinitely(true)
            }
            setManageBranchSchedules(userBranch.access.includes(UserBranchAccess.ManageBranchSchedules))
            setManageBranchUserShifts(userBranch.access.includes(UserBranchAccess.ManageBranchUserShifts))
            setViewBranchSchedules(userBranch.access.includes(UserBranchAccess.ViewBranchSchedules))
        } else {
            setBranchId(null)
            setStart(dayjs())
            setEnd(dayjs())
            setIndefinitely(false)
            setManageBranchSchedules(false)
            setManageBranchUserShifts(false)
            setViewBranchSchedules(false)
        }
    }, [open, data])

    const onBranchChange = useCallback((event: SelectChangeEvent) => {
        setBranchId(parseInt(event.target.value))
    }, [])

    const onStartChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setStartInvalid(false)
            setStart(value)
        } else {
            setStartInvalid(true)
        }
    }, [])

    const onEndChange = useCallback((value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) => {
        if (value && context.validationError === null) {
            setEndInvalid(false)
            setEnd(value)
        } else {
            setEndInvalid(true)
        }
    }, [])

    const onCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        switch (event.currentTarget.name) {
            case FormFieldNames.Indefinitely: {
                setIndefinitely(checked)

                if (checked) {
                    setEnd(dayjs())
                    setEndInvalid(false)
                }

                break
            }
            case FormFieldNames.ManageBranchSchedules:
                setManageBranchSchedules(checked)

                if (checked) {
                    setViewBranchSchedules(false)
                } else {
                    setManageBranchUserShifts(false)
                }

                break
            case FormFieldNames.ManageBranchUserShifts:
                setManageBranchUserShifts(checked)

                break
            case FormFieldNames.ViewBranchSchedules:
                setViewBranchSchedules(checked)

                break
        }
    }, [])

    function handleSaveClick(): void {
        const errors: FormErrors = {}

        if (branchId === null) {
            errors.branch = strings.error.branchRequired
        }

        if (startInvalid) {
            errors.start = strings.error.startInvalid
        }

        if (endInvalid && !indefinitely) {
            errors.end = strings.error.endInvalid
        }

        if (!startInvalid && !endInvalid) {
            if (start.isBefore(dayjs(), 'date')) {
                errors.start = strings.error.pastDateInvalid
            }

            if (!indefinitely && end !== null) {
                if (end.isBefore(dayjs(), 'date')) {
                    errors.end = strings.error.pastDateInvalid
                } else if (end.isBefore(start, 'date')) {
                    errors.end = strings.error.endBeforeStartInvalid
                }
            } else if (!indefinitely && end === null) {
                errors.end = strings.error.endInvalid
            }
        }

        setErrors(errors)

        if (!_.isEmpty(errors)) {
            return
        }

        const access: UserBranchAccess[] = []
        if (manageBranchSchedules) {
            access.push(UserBranchAccess.ManageBranchSchedules)
            if (manageBranchUserShifts) {
                access.push(UserBranchAccess.ManageBranchUserShifts)
            }
        } else if (viewBranchSchedules) {
            access.push(UserBranchAccess.ViewBranchSchedules)
        }

        const request = new UserBranchRequest(branchId!!, start.format('YYYY-MM-DD'), indefinitely ? null : end?.format('YYYY-MM-DD') ?? null, access)

        appDispatch(setInProgress())

        const promise: Promise<UserBranch> =
            userBranch !== null ? API.userBranches.edit(userId, userBranch.id, request) : API.userBranches.add(userId, request)

        promise
            .then((response) => {
                onSuccess(response)
                appDispatch(setSuccess())
                closeDialog()
            })
            .catch((error) => {
                appDispatch(setFailure())
                appDispatch(handleApiError(error))
            })
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{strings.label.title(userBranch !== null)}</DialogTitle>
            <DialogContent>
                <DialogContentText>&nbsp;</DialogContentText>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <FormControl
                        fullWidth={true}
                        error={errors.branch !== undefined}
                    >
                        <InputLabel>{strings.label.branch}</InputLabel>
                        <Select
                            id={FormFieldNames.Branch}
                            name={FormFieldNames.Branch}
                            value={branchId?.toString() ?? ''}
                            label={strings.label.branch}
                            onChange={onBranchChange}
                            disabled={userBranch !== null}
                        >
                            {branches.map(
                                (value: Branch, index: number): React.ReactNode => (
                                    <MenuItem
                                        key={index}
                                        value={value.id}
                                    >
                                        {value.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                        {!!errors.branch && <FormHelperText>{errors.branch}</FormHelperText>}
                    </FormControl>

                    <DatePicker
                        label={strings.label.start}
                        format="YYYY-MM-DD"
                        value={start}
                        disablePast={true}
                        slotProps={{
                            textField: {
                                helperText: errors.start,
                                error: errors.start !== undefined,
                            },
                        }}
                        name={FormFieldNames.Start}
                        onChange={onStartChange}
                    />

                    <DatePicker
                        label={strings.label.end}
                        format="YYYY-MM-DD"
                        value={indefinitely ? null : end}
                        disabled={indefinitely}
                        disablePast={true}
                        slotProps={{
                            textField: {
                                helperText: errors.end,
                                error: errors.end !== undefined,
                            },
                        }}
                        name={FormFieldNames.End}
                        onChange={onEndChange}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                name={FormFieldNames.Indefinitely}
                                checked={indefinitely}
                                onChange={onCheckboxChange}
                            />
                        }
                        label={strings.label.indefinitely}
                    />

                    <FormGroup>
                        <FormLabel component="legend">{strings.label.access}</FormLabel>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={FormFieldNames.ManageBranchSchedules}
                                    checked={manageBranchSchedules}
                                    disabled={viewBranchSchedules}
                                    onChange={onCheckboxChange}
                                />
                            }
                            label={strings.label.manageBranchSchedules}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={FormFieldNames.ManageBranchUserShifts}
                                    checked={manageBranchUserShifts}
                                    disabled={!manageBranchSchedules}
                                    onChange={onCheckboxChange}
                                />
                            }
                            label={strings.label.manageBranchUserShifts}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={FormFieldNames.ViewBranchSchedules}
                                    checked={viewBranchSchedules}
                                    disabled={manageBranchSchedules}
                                    onChange={onCheckboxChange}
                                />
                            }
                            label={strings.label.viewBranchSchedules}
                        />
                    </FormGroup>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{strings.button.cancel}</Button>
                <Button onClick={handleSaveClick}>{strings.button.save}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(UserBranchDialog)
