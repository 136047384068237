import React from 'react'
import { Holiday, ScheduleStatus } from '../../api/response'
import dayjs from 'dayjs'
import { styled } from '@mui/material'
import ScheduleTableHeaderDay from './ScheduleTableHeaderDay'
import _ from 'lodash'
import ScheduleTableHeaderInfo from './ScheduleTableHeaderInfo'

const StyledTableHead = styled('thead')(({ theme }) => ({
    '& th': {
        top: 0,
        zIndex: 1,
        minWidth: 150,
    },
    '& th:first-child': {
        ...theme.typography.caption,
        left: 0,
        zIndex: 2,
        minWidth: 300,
    },
}))

interface OwnProps {
    readonly date: dayjs.Dayjs
    readonly holidays: Holiday[]
    readonly status: ScheduleStatus | null
    readonly canManageSchedule: boolean
    readonly closeSchedule: () => Promise<void>
}

const ScheduleTableHeader: React.FunctionComponent<OwnProps> = ({ date, holidays, status, canManageSchedule, closeSchedule }) => {
    return (
        <StyledTableHead>
            <tr>
                <ScheduleTableHeaderInfo
                    date={date}
                    status={status}
                    canManageSchedule={canManageSchedule}
                    closeSchedule={closeSchedule}
                />
                {_.range(1, date.daysInMonth() + 1, 1).map((day, index) => (
                    <ScheduleTableHeaderDay
                        key={index}
                        date={date.date(day)}
                        holidays={holidays}
                    />
                ))}
            </tr>
        </StyledTableHead>
    )
}

export default React.memo(ScheduleTableHeader)
